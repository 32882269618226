import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import { Provider } from 'react-redux';
import App from './App';

import { Environment, getCurrentApiEnv } from './config/Environment';

import './i18n';
import { configureStore } from './redux/store';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={Environment.baseUrl[getCurrentApiEnv()].auth0Domain}
      clientId={Environment.baseUrl[getCurrentApiEnv()].auth0ClientId}
      audience={Environment.baseUrl[getCurrentApiEnv()].auth0Audience}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
      // useRefreshTokens
    >
      <Provider store={configureStore({})}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
