import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// auth
const Logout = React.lazy(() => import('../pages/account/Logout'));

const Orders = React.lazy(() => import('../pages/orders'));
const OrderDetails = React.lazy(() => import('../pages/orders/Details'));
const GenerateLabel = React.lazy(() => import('../pages/generateLabel'));

const ErrorPageNotFound = React.lazy(() =>
  import('../pages/error/PageNotFound')
);

const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const RedirectApp = React.lazy(() => import('../pages/account/RedirectApp'));
const MultipleLabelPage = React.lazy(() =>
  import('../pages/generateLabel/LabelPage')
);
const LabelPage = React.lazy(() =>
  import('../pages/orders/LabelPage/LabelPage')
);

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  name: 'Home',
  component: () => <Redirect to="/orders" />,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'uil-home-alt',
  header: 'Navigation',
  children: [
    {
      path: '/orders',
      name: 'orders',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Orders,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: '/orders/details/:id',
      name: 'ordersDetails',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: OrderDetails,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: '/generate-label',
      name: 'generateLabel',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: GenerateLabel,
      route: PrivateRoute,
      exact: true,
    },
  ],
};

const otherPublicRoutes = [
  {
    path: '/error-404',
    name: 'Error - 404',
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: ServerError,
    route: Route,
  },
  {
    path: '/redirect-app',
    name: 'RedirectApp',
    component: RedirectApp,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: '/account/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
];

const defaultLabelRoute = [
  {
    path: '/LabelPage/:tracking_number',
    name: 'LabelPage',
    component: LabelPage,
    route: PrivateRoute,
  },
  {
    path: '/MultipleLabelPage/:trackingNumbers/:labelType',
    name: 'multipleLabelPage',
    component: MultipleLabelPage,
    route: PrivateRoute,
  },
];

// All routes
const authProtectedRoutes = [dashboardRoutes];
const publicRoutes = [
  rootRoute,
  ...authRoutes,
  ...otherPublicRoutes,
  ...defaultLabelRoute,
];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
