// @flow
import { ReactElement } from 'react';
import Routes from './routes/Routes';

import './assets/scss/Saas.scss';

const App = (): ReactElement => {
  return <Routes />;
};

export default App;
